import { defineStore } from 'pinia'
import { useLocalStorage, type RemovableRef } from '@vueuse/core'

type TokenState = {
  token: RemovableRef<string | null>
  validTo: RemovableRef<string | null>
  studentToken: RemovableRef<string | null>
  studentValidTo: RemovableRef<string | null>
}

export const useTokenStore = defineStore('token', {
  state: () =>
    ({
      token: useLocalStorage('token', null),
      validTo: useLocalStorage('validTo', null),
      studentToken: useLocalStorage('student', null),
      studentValidTo: useLocalStorage('studentValidTo', null),
    }) as TokenState,
  getters: {
    hasToken(): boolean {
      return !!this.token
    },
  },
  actions: {
    hasValidToken(): boolean {
      if (this.validTo) {
        const validToDate = new Date(this.validTo)
        if (validToDate < new Date()) {
          return false
        }
      }
      return !!this.token
    },
    setToken(token: string, validTo: Date) {
      this.token = token
      this.validTo = validTo.toISOString()
    },
    setStudentToken(token: string, validTo: Date) {
      this.studentToken = token
      this.studentValidTo = validTo.toISOString()
    },
    clear() {
      this.token = null
      this.validTo = null
      this.studentToken = null
      this.studentValidTo = null
    },
  },
})

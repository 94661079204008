import http, { throwOnAxiosError } from './http'

export async function groupsGetBySubjectId(subjectId) {
  try {
    const res = await http.get('/v1/me/groups', {
      params: {
        subjectId,
      },
    })
    const groups = res.data.map((group) => ({
      ...group,
      courseDisplayName: `${group.courses.map((c) => c.name).join(', ')}`,
    }))
    return groups
  } catch (err) {
    throwOnAxiosError(err, 'Failed to get groups')
  }
}

export async function groupGetById(id) {
  try {
    const res = await http.get(`/v1/me/groups/${id}`)
    return res.data
  } catch (err) {
    throwOnAxiosError(err, 'Failed to get group by id')
  }
}

export async function groupCreate(data) {
  try {
    const res = await http.post(`/v1/me/groups`, data)
    return res.data
  } catch (err) {
    throwOnAxiosError(err, 'Failed to create a group')
  }
}

export async function groupUpdate(id, data) {
  try {
    const res = await http.patch(`/v1/me/groups/${id}`, data)
    return res.data
  } catch (err) {
    throwOnAxiosError(err, 'Failed to update a group')
  }
}

export async function groupDelete(id) {
  try {
    const res = await http.delete(`/v1/me/groups/${id}`)
    return res.data
  } catch (err) {
    throwOnAxiosError(err, 'Failed to delete a group')
  }
}

export async function groupArchieve(id) {
  try {
    const res = await http.post(`/v1/me/groups/${id}/archive`, {
      archivedAt: new Date(),
    })
    return res.data
  } catch (err) {
    throwOnAxiosError(err, 'Failed to archive a group')
  }
}

export async function groupStudentsAdd(id, data) {
  try {
    const res = await http.post(`/v1/me/groups/${id}/students`, data)
    return res.data
  } catch (err) {
    throwOnAxiosError(err, 'Failed to add students to a group')
  }
}

export async function groupStudentsDelete(id, studentIds) {
  try {
    const res = await http.delete(`/v1/me/groups/${id}/students`, {
      data: { students: studentIds },
    })
    return res.data
  } catch (err) {
    throwOnAxiosError(err, 'Failed to delete students from a group')
  }
}

export async function groupStudentsGetAll(groupId) {
  try {
    const res = await http.get(`/v1/me/groups/${groupId}/students`)
    return res.data
  } catch (err) {
    throwOnAxiosError(err, 'Failed to get students')
  }
}

export async function groupStudentsTakingCourse(groupId, courseId, examId) {
  try {
    const res = await http.get(
      `/v1/me/groups/${groupId}/courses/${courseId}/students?examId=${examId}`
    )
    return res.data
  } catch (err) {
    throwOnAxiosError(err, 'Failed to get students in the course')
  }
}

export async function groupExamsSearch(groupId, params) {
  try {
    const res = await http.get(`/v1/me/groups/${groupId}/exams`, {
      params: { page: params.page, pageSize: params.pageSize },
    })
    return res.data
  } catch (err) {
    throwOnAxiosError(err, 'Failed to find group exams')
  }
}

export async function groupExamAdd(groupId, examId) {
  try {
    const res = await http.post(`/v1/me/groups/${groupId}/exams`, {
      examId,
    })
    return res.data
  } catch (err) {
    throwOnAxiosError(err, 'Failed to add exam to a group')
  }
}

export async function groupExamDelete(groupId, examId) {
  try {
    const res = await http.delete(`/v1/me/groups/${groupId}/exams/${examId}`)
    return res.data
  } catch (err) {
    throwOnAxiosError(err, 'Failed to delete exam from group')
  }
}
